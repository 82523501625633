import React, {FunctionComponent} from "react";

import {Gender} from "../../../../types";
import {bemClassNames} from "../../../../utils";
import {Link, Header} from "../../../../components";

const classNames = bemClassNames("level-info-component");

export type LevelInfoComponentProps = {
    title?: string;
    videoLink?: string;
    sheetLink?: string;
    duration?: number;
    gender: Gender;
}

export const LevelInfoComponent: FunctionComponent<LevelInfoComponentProps> = ({title, videoLink, sheetLink, duration, gender}) => (
    <div className={classNames()}>
        <Header level={3} textAlign="left">{gender === Gender.man ? "Man" : "Woman"} {title}</Header>
        <Link href={videoLink}>Video</Link>&nbsp;&nbsp;
        <Link href={sheetLink}>PDF Sheet</Link><br />
        {duration && (
            <span>Must be done under {duration} minutes.</span>
        )}
    </div>
);