import {FunctionComponent, useState, ReactElement} from "react"

export type BooleanSwitcherChildrenProps = {
    value: boolean;
    switchOn: () => void;
    switchOff: () => void;
}

export type BooleanSwitcherProps = {
    children: (props: BooleanSwitcherChildrenProps) => ReactElement;
}

export const BooleanSwitcher: FunctionComponent<BooleanSwitcherProps> = ({children}) => {
    const [value, setValue] = useState(false);

    const switchOn = () => setValue(true);

    const switchOff = () => setValue(false);

    return children({value, switchOn, switchOff});
};
