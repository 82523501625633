import React, {FunctionComponent} from "react";

import "./Cell.css";

export type CellProps = {
    paddingRight?: number;
}

export const Cell: FunctionComponent<CellProps> = ({children}) => (
    <td className="cell">
        {children}
    </td>
);
