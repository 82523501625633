import React, {FunctionComponent} from "react";

import {RouterLink} from "../RouterLink";
import "./Menu.css";

const MENU_ITEMS = [
    ["Home", "Raw Workout", "/home"],
    ["Team", "Raw Team", "/team"],
    ["Levels", "Levels", "/levels"],
    ["Statistics", "Statistics", "/statistics"],
    ["Map", "World Map", "/map"],
];

export const Menu: FunctionComponent = () => (
    <div className="menu">
        {MENU_ITEMS.map(([text, title, link]) => (
            <div key={text} className="menu__item" {...{title}}>
                <RouterLink to={link}>{text.toUpperCase()}</RouterLink>
            </div>
        ))}
    </div>
);
