import React, {FunctionComponent} from "react";

import {Level, Gender} from "../../../../types";
import {bemClassNames} from "../../../../utils";
import {Header} from "../../../../components";
import {LevelInfoComponent} from "../LevelInfoComponent";
import "./LevelComponent.css";

export type LevelComponentProps = Level;

const classNames = bemClassNames("level-component");

export const LevelComponent: FunctionComponent<LevelComponentProps> = ({textContent, empty, man, woman, number}) => (
    <div className={classNames()}>
        <div className={classNames("image")}>
            <img alt="Level" src={`/files/level/${number}.png`} style={{width: 300, height: 300}} />
        </div>
        {empty ? (
            <div className={classNames("info")}>
                <Header level={3} textAlign="left">{man?.title || woman?.title}</Header>
                {textContent}
                <br />
            </div>
        ) : (
            <div className={classNames("info")}>
                {textContent}<br />
                <LevelInfoComponent {...man} gender={Gender.man} />
                <LevelInfoComponent {...woman} gender={Gender.woman} />
            </div>
        )}
    </div>
);
