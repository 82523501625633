import React, {FunctionComponent} from "react";

import {levels} from "../../data/levels";
import {bemClassNames} from "../../utils";
import {Page, Header, Layout, SpacingSize} from "../../components";
import {LevelComponent} from "./components";
import "./Levels.css";

const classNames = bemClassNames("levels");

export const Levels: FunctionComponent = () => (
    <Page>
        <div>
            <Header level={1}>LEVELS</Header>
            <Layout spacingSize={SpacingSize.xs}>
                <div className={classNames("content")}>
                    <span className="grey_text">
                        Uncut, no music, no speed changes, no edit at all. All weights must be clearly shown and exercises done in same
                        order like on requirements official video.
                        No equipement (squat belt, wraps...) is allowed and also no shoes (MEN) and no chalk on hands! Keep it RAW!
                        Send your links to <b>INFO@ADAMRAW.CZ</b> for checking.
                    </span>
                    <br />
                    <hr />
                    <LevelComponent {...levels["0"]} />
                    <LevelComponent {...levels["1"]} />
                    <LevelComponent {...levels["2"]} />
                    <LevelComponent {...levels["3"]} />
                    <LevelComponent {...levels["98"]} />
                    <LevelComponent {...levels["99"]} />
                </div>
            </Layout>
        </div>
    </Page>
);
