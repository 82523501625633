import React, { FunctionComponent } from "react";

import { Link } from "../Link";
import "./Footer.css";

export const Footer: FunctionComponent = () => (
    <Link className="footer" href="http://pavelpo.cz/">
        Pavel Pospíšil 2017–{(new Date()).getFullYear()}
    </Link>
);
