import React, {FunctionComponent} from "react";

export type DetailLineProps = {
    title: string;
}

export const DetailLine: FunctionComponent<DetailLineProps> = ({title, children}) => (
    <>
        <span className="grey_text">{title}:</span> {children}<br />
    </>
);
