import React, {FunctionComponent} from "react";

import {Footer} from "../Footer";
import "./Page.css";

export const Page: FunctionComponent = ({children}) => (
    <div className="page">
        <div className="page__content">
            {children}
            <Footer />
        </div>
    </div>
);
