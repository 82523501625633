export const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
};

const WHITE = {r: 255, g: 255, b: 255};

export const setAlpha = (color: string, alpha: number) => {
    const {r, g, b} = hexToRgb(color) || WHITE;
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
