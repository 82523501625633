import { ReactElement } from "react";

export type Member = {
    id: string;
    name: string;
    countryCode: CountryCode;
    gender: Gender;
    addedTimestamp?: number;
    city: string;
    levelNumber: LevelNumber;
    photoDisabled?: boolean;
    facebook?: {
        nick: string;
        link: string;
    }
    youtube?: {
        link: string;
        nick: string;
    }
    instagramNick?: string;
    requirementsVideoLink?: string;
    detail?: string;
    website?: {
        name: string;
        link: string;
    }
}

export type GenderLevelInfo = {
    title: string;
    videoLink?: string;
    sheetLink?: string;
    duration?: number;
}

export type Level = {
    number: LevelNumber;
    color: string;
    man?: GenderLevelInfo;
    woman?: GenderLevelInfo;
    textContent?: string | ReactElement;
    empty?: boolean;
    blocked?: boolean;
}

export type LevelNumber =  "0" | "1" | "2" | "3" | "4" | "98" | "99";

export enum Gender {
    man = "man",
    woman = "woman",
}

export enum CountryCode {
    CZ = "CZ",
    SK = "SK",
    ARG = "ARG",
    SLO = "SLO",
    SPA = "SPA",
    ALG = "ALG",
    HUN = "HUN",
    IRE = "IRE",
    USA = "USA",
    UKR = "UKR",
    IND = "IND",
    ITA = "ITA",
    POL = "POL",
    GER = "GER",
    RUS = "RUS",
    AU = "AU",
}

export type Country = {
    wikipediaLink: string;
    name: string;
    code: CountryCode;
    color: string;
}

export type Dataset = {
    data: number[];
    backgroundColor: string[];
    hoverBackgroundColor: string[];
}

export type Graph = {
    name: string;
    labels: string[];
    datasets: Dataset[];
}
