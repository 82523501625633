import React, {FunctionComponent} from "react";

export const SPACING_STEP = 8;

export enum SpacingSize {
    // xxs = 0.5,
    xs  = 1,
    // s = 1.5,
    // l = 2,
    // xl = 2.5,
    // xxl = 3,
}

export type LayoutProps = {
    spacingSize: SpacingSize;
}

export const Layout: FunctionComponent<LayoutProps> = ({spacingSize, children}) => (
    <div style={{padding: spacingSize * SPACING_STEP}}>
        {children}
    </div>
);
