import React, {FunctionComponent} from "react";

import {getMembers, getLevelTooltipText, getCountryName, gteCountryWikipediaLink, isNewMember} from "../../../../selectors";
import {bemClassNames, capitalize} from "../../../../utils";
import {RouterLink, Link} from "../../../../components";
import {Row, Cell, TooltipCell, TableImage} from "./components";
import "./Table.css";

const classNames = bemClassNames("table");

export const Table: FunctionComponent = () => (
    <table className={classNames()}>
        <tbody>
            {getMembers().map(({id, name, city, gender, levelNumber, photoDisabled, facebook, youtube, instagramNick, requirementsVideoLink}) => {
                const levelTitle = getLevelTooltipText(id);
                const countryName = getCountryName(id);
                const wikipediaLink = gteCountryWikipediaLink(id);

                return (
                    <Row key={id}>                        
                        <Cell paddingRight={20}>
                            {isNewMember(id) && (
                                <TableImage
                                    src="files/icons/new.png"
                                    alt="NEW"
                                    style={{marginRight: 5, verticalAlign: "middle"}}
                                    title="In the team less than 14 days"
                                />
                            )}
                            <span style={{verticalAlign: "middle"}}>
                                {name}
                            </span>
                        </Cell>
                        <Cell paddingRight={20}>
                            {city}
                        </Cell>
                        <TooltipCell text={capitalize(gender)} paddingRight={10}>
                            <TableImage
                                src={`files/icons/${gender}.png`}
                                alt={capitalize(gender)}
                            />
                        </TooltipCell>
                        <TooltipCell text={levelTitle} paddingRight={10}>
                            <RouterLink to="levels">
                                <TableImage
                                    src={`files/level/${levelNumber}.png`}
                                    alt={levelTitle}
                                />
                            </RouterLink>
                        </TooltipCell>
                        <TooltipCell text={`Wikipedia: ${countryName}`}>
                            <Link href={wikipediaLink}>
                                <TableImage
                                    src={`files/flag/${countryName}.png`}
                                    alt={countryName}
                                />
                            </Link>
                        </TooltipCell>
                        {photoDisabled ? (
                            <TooltipCell text="Missing photo">
                                <TableImage
                                    src="files/icons/profile_unused.png"
                                    alt="Unused"
                                />
                            </TooltipCell>
                        ) : (
                            <TooltipCell text="View profile">
                                <RouterLink to={`detail/${name.replace(" ", "-")}`}>
                                    <TableImage src="files/icons/profile.png" alt="Profile" />
                                </RouterLink>
                            </TooltipCell>
                        )}
                        {facebook ? (
                            <TooltipCell text="Facebook">
                                <Link href={facebook.link}>
                                    <TableImage src="files/icons/facebook.png" alt="Facebook" />
                                </Link>
                            </TooltipCell>
                        ) : (
                            <Cell>
                                <TableImage src="files/icons/facebook_unused.png" alt="Unused" />
                            </Cell>
                        )}
                        {youtube ? (
                            <TooltipCell text="YouTube Channel">
                                <Link href={youtube.link}>
                                    <TableImage src="files/icons/youtube.png" alt="YouTube Channel" />
                                </Link>
                            </TooltipCell>
                        ) : (
                            <Cell>
                                <TableImage src="files/icons/youtube_unused.png" alt="Unused" />
                            </Cell>
                        )}
                        {instagramNick ? (
                            <TooltipCell text="Instagram">
                                <Link href={`https://www.instagram.com/${instagramNick}`}>
                                    <TableImage src="files/icons/instagram.png" alt="Instagram" />
                                </Link>
                            </TooltipCell>
                        ) : (
                            <Cell>
                                <TableImage src="files/icons/instagram_unused.png" alt="Unused" />
                            </Cell>
                        )}
                        {requirementsVideoLink ? (
                            <TooltipCell text="Requirements video">
                                <Link href={requirementsVideoLink}>
                                    <TableImage src="files/icons/accepted_video.png" alt="Requirements video" />
                                </Link>
                            </TooltipCell>
                        ) : (
                            <Cell>
                                <TableImage src="files/icons/accepted_video_unused.png" alt="Unused" />
                            </Cell>
                        )}
                    </Row>
                );
            })}
        </tbody>
    </table>
);
