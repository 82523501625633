import React from "react";

import {Level} from "../types";
import {Link} from "../components";

export const levels: {[levelNumber: string]: Level} = {
    "0": {
        number: "0",
        color: "#efbf00",
        textContent: "Will be written in this website and accepted into Novices RW Team and allowed to buy Raw Workout Members apparel (Suit and Yellow Novice Bandana).",
        man: {
            title: "Novice",
            videoLink: "https://youtu.be/K93F6uEfoGk?t=10m2s",
            sheetLink: "/files/pdf/Men%20Novice.pdf",
            duration: 15,
        },
        woman: {
            title: "Novice",
            videoLink: "https://youtu.be/cpIuAdEG8kM?t=6s",
            sheetLink: "/files/pdf/Women%20Novice.pdf",
            duration: 6,
        },
    },
    "1": {
        number: "1",
        color: "#bf2313",
        textContent: "Will get free Membership Black and Red RW bandana sent to his home (Worldwide), also be written in this website and is allowed to buy Raw Workout Members apparel with DISCOUNT (RW Suit).",
        man: {
            title: "Member",
            videoLink: "https://youtu.be/K93F6uEfoGk?t=16m30s",
            sheetLink: "/files/pdf/Men%20Member.pdf",
            duration: 15,
        },
        woman: {
            title: "Member",
            videoLink: "https://youtu.be/-JUxH8adzPs?t=4m4s",
            sheetLink: "/files/pdf/Women%20Member.pdf",
            duration: 7.5,
        },
    },
    "2": {
        number: "2",
        color: "#0da94d",
        textContent: "Will get free Membership Black and Green Warrior Tank top and is allowed to buy Raw Workout Members apparel with even BIGGER DISCOUNT (RW Suit).",
        man: {
            title: "Warrior",
            videoLink: "https://youtu.be/K93F6uEfoGk?t=22m24s",
            sheetLink: "/files/pdf/Men%20Warrior.pdf",
            duration: 18,
        },
        woman: {
            title: "Amazon",
            videoLink: "https://youtu.be/Fgwm7jpAs14",
            sheetLink: "/files/pdf/Women%20Amazon.pdf",
            duration: 20,
        },
    },
    "3": {
        number: "3",
        color: "gold",
        empty: true,
        textContent: "Preparing...",
        man: {
            title: "General",
        },
    },
    "98": {
        number: "98",
        color: "#6e246f",
        textContent: "This special level includes members who stood at the birth of Raw Workout and inspire strongly by their own workout example. It can only be achieved as an award given by Adam Raw and is not depandant on any requirements.",
        empty: true,
        woman: {
            title: "Ancient",
        },
    },
    "99": {
        number: "99",
        color: "#111111",
        textContent: <>This level belongs only to the Raw Workout founder <Link href="detail/Adam-Raw" title="View profile">Adam Raw</Link>.</>,
        empty: true,
        man: {
            title: "Founder",
        },
    },
};
