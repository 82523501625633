import React, {FunctionComponent, useEffect} from "react";
import Chart from "chart.js";

import {graphs} from "../../constants";
import {bemClassNames} from "../../utils";
import {Page, Header} from "../../components";
import "./Statistics.css";

const classNames = bemClassNames("statistics");

export const Statistics: FunctionComponent = () => {
    useEffect(() => {
        graphs.forEach(({name, labels, datasets}) => {
            const chartElement = document.getElementById(`chart-${name}`) as HTMLCanvasElement;

            if (!chartElement) {
                return;
            }

            new Chart(chartElement, {
                type: "doughnut",
                data: {
                    labels,
                    datasets,
                },
            });
        });
    });

    return (
        <Page>
            <Header level={1}>Statistics</Header>
            <div className={classNames("graphs")}>
                {graphs.map(({name}) => (
                    <div key={name} className={classNames("graph")}>
                        <Header level={2}>{name}</Header>
                        <canvas
                            id={`chart-${name}`}
                            width="400"
                            height="400"
                        />
                    </div>
                ))}
            </div>
        </Page>
    );
};
