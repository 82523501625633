import React, {FunctionComponent} from "react";

import {Page, Header, BooleanSwitcher} from "../../components";

export const Map: FunctionComponent = () => (
    <BooleanSwitcher>
        {({value: mapVisible, switchOn: handleMapLoad}) => (
            <Page>
                <Header level={1}>WORLD MAP</Header>
                {!mapVisible && (
                    <div>
                        <Header level={2}>Loading</Header>
                    </div>
                )}
                <div style={{visibility: mapVisible ? "visible" : "hidden"}}>
                    <iframe
                        onLoad={handleMapLoad}
                        title="Team Map"
                        style={{border: "solid #BBB 1px"}}
                        src="https://www.google.com/maps/d/embed?mid=1Xs1jhO88haQeC6t2hfv6auFIw-Q"
                        width="900"
                        height="600"
                    />
                </div>
            </Page>
        )}
    </BooleanSwitcher>
);
