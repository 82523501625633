import React, {FunctionComponent} from "react";

import {Router} from "./Router";
import "./App.css";

export const App: FunctionComponent = () => (
    <div className="app">
        <React.StrictMode>
            <Router />
        </React.StrictMode>
    </div>
);
