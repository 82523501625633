import React, {FunctionComponent, Fragment} from "react";

import {Page, Header, Layout, SpacingSize} from "../../components";
import {bemClassNames} from "../../utils";
import "./Home.css";

const YOUTUBE_VIDEOS = [
    ["Men lvl. 0, 1 and 2 - Novice, Member and Warrior", "https://www.youtube.com/embed/K93F6uEfoGk?rel=0"],
    ["Women lvl. 0 - Novice", "https://www.youtube.com/embed/cpIuAdEG8kM?rel=0"],
    ["Women lvl. 1 - Member", "https://www.youtube.com/embed/-JUxH8adzPs?rel=0"],
    ["Women lvl. 2 - Amazon", "https://www.youtube.com/embed/Fgwm7jpAs14"],
];

const classNames = bemClassNames("home");

export const Home: FunctionComponent = () => (
    <Page>
        <div className={classNames()}>
            <img className={classNames("title_photo")} src="files/title_photo.jpg" alt="Title" />
            <Header level={1}>Raw workout</Header>

            <Layout spacingSize={SpacingSize.xs}>
                <p>
                    <b>ENG:</b> Raw Workout community consists of many thousands of people, fans, followers
                    and true ones to healthy lifestyle and proper full body workout. To motivate all these
                    and give them proper example - we are building <b>elite team</b> of really all-rounded athlete
                    individuals who will not just lead Raw Workout in their city but also can attend our
                    events and movement. To become official member of RAW WORKOUT TEAM - you must finish
                    <b>RAW WORKOUT REQUIREMENTS</b> (at least lvl. 0) as shown in videos below. If done properly
                    and officialy accepted by Adam Raw, your name will be written on this page and you
                    will get special benefits or items depanding on your level. Your position in team
                    starts at minimum lvl. 0 Novice - this is not yet Core Team with membership bandana
                    for free tho, but you are able to buy RW apparel as Novice (for example Raw Workout
                    Suit or Yellow Novice RW Bandanas etc.). Core team starts with lvl. 1 MEMBER and you
                    are getting free black and red RW bandana for it, than you can individually grow
                    further and rise your workout level depanding on future challenges. You can also
                    attend RW official events and lead RW community in your country if you wish and
                    can. You must properly inspire by your own example and meet general RW ideas
                    honestly. <b>It is possible to be member of other teams and still be part of RW officially.</b>
                </p>

                <br />

                <p>
                    <b>CZ:</b> Raw Workout komunita sestává z tisíců a tisíců lidí, kteří se rozhodli pro
                    aktivní život bez drog, alkoholu a nezdravého jídla a zároveň se snaží učit ovládat
                    své tělo (hlavně prostřednictvím základních cviků, kalisteniky a jejích variací) - abychom
                    všechny tyto super lidi motivovali ještě více a dodali jim pohon a inspiraci,
                    sestavujeme elitní <b>MOTIVAČNÍ TEAM</b>, plný skutečně všestranných atletů-jedinců,
                    cvičících správnou formou celé své tělo dle idejí Raw Workoutu a snažících se
                    pomáhat svým vlastním příkladem i začátečníkům a slabším s hlavním kladením důrazu
                    na zdraví a potenciál cvičení do budoucna. Členem RAW WORKOUT TÝMU se může oficiálně
                    stát kdokoli (on i ona), kdo čestně a vlastním příkladem splňuje všeobecné ideje RW
                    a zároveň zvládne některou úroveň <b>RAW WORKOUT ZKOUŠEK</b> (REQUIREMENTS) dle videí níže.
                </p>

                <p>
                    Po splnění zkoušky a uznání Adamem Raw je jméno každého člena zveřejněno na této
                    stránce. Každý nový člen získá Raw Workout hodnost dle své dosažené úrovně
                    (úroveň 0 - Novic, úroveň 1 - Member neboli ČLEN, úroveň 2 - Warrior neboli Bojovník atd...) a
                    speciální týmové výhody, předměty a další možnosti RW. Dále bude
                    mít každý člen individuální možnosti podílet se na akcích RW, osobně v týmu růst a
                    zvyšovat do  budoucna svou workoutovou hodnost. <b>Je samozřejmě možné být
                    současně také členem jiných týmů i RW současně.</b>
                </p>

                {YOUTUBE_VIDEOS.map(([title, link]) => (
                    <Fragment key={title}>
                        <Header level={3}>{title}</Header>
                        <p className={classNames("youtube_video")}>
                            <iframe width="600" height="400" src={link} {...{title}} frameBorder="0" allowFullScreen={true} />
                        </p>
                    </Fragment>
                ))}
            </Layout>
        </div>
    </Page>
);
