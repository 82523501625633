import React, {FunctionComponent, CSSProperties} from "react";

import "./Header.css";

export type HeaderProps = {
    level: 1 | 2 | 3;
    textAlign?: CSSProperties["textAlign"];
}

export const Header: FunctionComponent<HeaderProps> = ({level, textAlign, children}) => {
    if (level === 1) {
        return <h1 style={{textAlign}} className={`header--${level}`}>{children}</h1>
    }

    if (level === 2) {
        return <h2 style={{textAlign}} className={`header--${level}`}>{children}</h2>
    }

    if (level === 3) {
        return <h3 style={{textAlign}} className={`header--${level}`}>{children}</h3>
    }

    return null;
};
