import React from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";

import {Menu, ScrollToTop} from "./components";
import {Team, Home, Map, Statistics, Detail, Levels} from "./screens";

export const Router = () => (
    <BrowserRouter>
        <ScrollToTop />
        <Menu />
        <Switch>
            <Route path="/team" component={Team} />
            <Route path="/map" component={Map} />
            <Route path="/statistics" component={Statistics} />
            <Route path="/levels" component={Levels} />
            <Route path="/detail/:name" component={Detail} />
            <Route path="/" component={Home} />
        </Switch>
    </BrowserRouter>
);
