import React, {CSSProperties, FunctionComponent} from "react";

import {bemClassNames} from "../../../../../../utils";
import "./TableImage.css";

export type TableImageProps = {
    src: string;
    alt?: string;
    style?: CSSProperties;
    title?: string;
}

const classNames = bemClassNames("table-image");

export const TableImage: FunctionComponent<TableImageProps> = ({src, alt, style, title}) => (
    <img
        className={classNames()}
        {...{src, alt, style, title}}
    />
);
