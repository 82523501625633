import React, {FunctionComponent} from "react";

import {bemClassNames} from "../../utils";
import {getMenCount, getWomenCount, getMembersCount} from "../../selectors";
import {Page, Header, Layout, SpacingSize} from "../../components";
import {Table} from "./components";
import "./Team.css";

const classNames = bemClassNames("team");

export const Team: FunctionComponent = () => (
    <Page>
        <div className={classNames()}>
            <Header level={1}>Raw Team</Header>
            <Layout spacingSize={SpacingSize.xs}>
                <Table />
            </Layout>
            <p className={classNames("footer")}>
                {getMembersCount()} members ({getMenCount()} men, {getWomenCount()} women)
            </p>
        </div>
    </Page>
);
