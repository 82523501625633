import {Country, CountryCode} from "../types";

export const countries: {[countryCode: string]: Country} = {
    [CountryCode.CZ]: {code: CountryCode.CZ, name: "Czech Republic", wikipediaLink: "https://en.wikipedia.org/wiki/Czech Republic", color :"#E61717"},
    [CountryCode.SK]: {code: CountryCode.SK, name: "Slovakia", wikipediaLink: "https://en.wikipedia.org/wiki/Slovakia", color :"#CD6314"},
    [CountryCode.ARG]: {code: CountryCode.ARG, name: "Argentina", wikipediaLink: "https://en.wikipedia.org/wiki/Argentina", color :"#E6C917"},
    [CountryCode.SLO]: {code: CountryCode.SLO, name: "Slovenia", wikipediaLink: "https://en.wikipedia.org/wiki/Slovenia", color :"#98CD14"},
    [CountryCode.SPA]: {code: CountryCode.SPA, name: "Spain", wikipediaLink: "https://en.wikipedia.org/wiki/Spain", color :"#52E617"},
    [CountryCode.ALG]: {code: CountryCode.ALG, name: "Algeria", wikipediaLink: "https://en.wikipedia.org/wiki/Algeria", color :"#14CD2F"},
    [CountryCode.HUN]: {code: CountryCode.HUN, name: "Hungary", wikipediaLink: "https://en.wikipedia.org/wiki/Hungary", color :"#17E68E"},
    [CountryCode.IRE]: {code: CountryCode.IRE, name: "Ireland", wikipediaLink: "https://en.wikipedia.org/wiki/Ireland", color :"#14CDCD"},
    [CountryCode.USA]: {code: CountryCode.USA, name: "USA", wikipediaLink: "https://en.wikipedia.org/wiki/USA", color :"#178EE6"},
    [CountryCode.UKR]: {code: CountryCode.UKR, name: "Ukraine", wikipediaLink: "https://en.wikipedia.org/wiki/Ukraine", color :"#142FCD"},
    [CountryCode.IND]: {code: CountryCode.IND, name: "India", wikipediaLink: "https://en.wikipedia.org/wiki/India", color :"#5217E6"},
    [CountryCode.ITA]: {code: CountryCode.ITA, name: "Italy", wikipediaLink: "https://en.wikipedia.org/wiki/Italy", color :"#9814CD"},
    [CountryCode.POL]: {code: CountryCode.POL, name: "Poland", wikipediaLink: "https://en.wikipedia.org/wiki/Poland", color :"#E617C9"},
    [CountryCode.GER]: {code: CountryCode.GER, name: "Germany", wikipediaLink: "https://en.wikipedia.org/wiki/Germany", color :"#CD1463"},
    [CountryCode.RUS]: {code: CountryCode.RUS, name: "Russia", wikipediaLink: "https://en.wikipedia.org/wiki/Russia", color :"#1d1dd5"},
    [CountryCode.AU]: {code: CountryCode.AU, name: "Australia", wikipediaLink: "https://en.wikipedia.org/wiki/Australia", color :"#f4720d"},
};
