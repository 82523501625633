import React, {FunctionComponent} from "react";

import {getLevelTooltipText, gteCountryWikipediaLink, getCountryName, getLevelTitle, getMembers} from "../../selectors";
import {capitalize, bemClassNames} from "../../utils";
import {Page, Header, RouterLink, Link, Layout, SpacingSize} from "../../components";
import {DetailLine} from "./components";
import "./Detail.css";

export type DetailProps = {
    match: any;
}

const classNames = bemClassNames("detail");

export const Detail: FunctionComponent<DetailProps> = ({match}) => {
    const member = getMembers().find((member) => match.params.name.replace("-", " ") === member.name);

    if (!member) {
        return (
            <Header level={2}>Member doesn't exist!</Header>
        );
    }

    const {id, levelNumber, name, city, facebook, instagramNick, youtube, website, requirementsVideoLink, detail} = member;
    const countryName = getCountryName(id);

    return (
        <Page>
            <div style={{textAlign: "left"}}>
                <Header level={1}>
                    <RouterLink to="/levels">
                        <img
                            className={classNames("member_icon")}
                            src={`/files/level/${levelNumber}.png`}
                            title={getLevelTooltipText(id)}
                            alt={getLevelTooltipText(id)}
                        />
                    </RouterLink>
                    &nbsp;&nbsp;{capitalize(name)}&nbsp;&nbsp;
                    <Link href={gteCountryWikipediaLink(id)}>
                        <img
                            className={classNames("member_icon")}
                            src={`/files/flag/${countryName}.png`}
                            title={`Wikipedia: ${countryName}`}
                            alt={countryName}
                        />
                    </Link>
                </Header>
                <Link href={`/files/photo/${name}.jpg`}>
                    <img
                        className={classNames("member_photo")}
                        src={`/files/photo/${name}.jpg`}
                        title={name}
                        alt={name}
                    />
                </Link>
		        <br />
                <Layout spacingSize={SpacingSize.xs}>
                    <span style={{fontSize: 30}}>
                        <DetailLine title="City">{city}</DetailLine>
                        <DetailLine title="Country">{countryName}</DetailLine>
                        <DetailLine title="Level">
                            <RouterLink to="/levels" title="More info">{getLevelTitle(id)}</RouterLink>
                        </DetailLine>
                        {facebook && (
                            <DetailLine title="Facebook">
                                <Link href={facebook.link}>{facebook.nick}</Link>
                            </DetailLine>
                        )}
                        {youtube && (
                            <DetailLine title="YouTube Channel">
                                <Link href={youtube.link}>{youtube.nick}</Link>
                            </DetailLine>
                        )}
                        {instagramNick && (
                            <DetailLine title="Instagram">
                                <Link href={`https://www.instagram.com/${instagramNick}`}>{instagramNick}</Link>
                            </DetailLine>
                        )}
                        {website && (
                            <DetailLine title="Website">
                                <Link href={website.link}>{website.name}</Link>
                            </DetailLine>
                        )}
                        {requirementsVideoLink && (
                            <DetailLine title="Requirements video">
                                <Link href={requirementsVideoLink}>Here</Link>
                            </DetailLine>
                        )}
                        {detail && (
                            <DetailLine title="Detail">
                                {detail}
                            </DetailLine>
                        )}
                    </span>
                </Layout>
            </div>
        </Page>
    );
};
