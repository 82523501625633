import {members} from "./data/members";
import {Gender} from "./types";
import {levels} from "./data/levels";
import {getTimestamp, daysToMilliseconds} from "./utils";
import {countries} from "./data/countries";

export const getMembers = () => members
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => parseInt(b.levelNumber) - parseInt(a.levelNumber));

export const getMember = (id: string) => getMembers().find((member) => member.id === id);

export const isMan = (id: string) => {
    const member = getMember(id);

    if (!member) {
        return false;
    }

    return member.gender === Gender.man;
}

export const getLevel = (levelNumber: string) => levels[levelNumber];

export const getLevelTooltipText = (id: string) => {
    const levelTitle = getLevelTitle(id);

    if (!levelTitle) {
        return "";
    }

    return `Level: ${levelTitle}`;
};

export const getLevelTitle = (id: string) => {
    const member = getMember(id);

    if (!member) {
        return undefined;
    }

    const {levelNumber} = member;
    const level = getLevel(levelNumber);

    if (!level) {
        return undefined;
    }

    return isMan(id) ? level?.man?.title : level?.woman?.title;
};

export const getCountry = (id: string) => {
    const member = getMember(id);

    if (!member) {
        return undefined;
    }

    return countries[member.countryCode];
}

export const getCountryName = (id: string) => {
    const country = getCountry(id);

    if (!country) {
        return undefined;
    }

    return country.name;
};

export const gteCountryWikipediaLink = (id: string) => {
    const country = getCountry(id);

    if (!country) {
        return undefined;
    }

    return country.wikipediaLink;
};

export const isNewMember = (id: string) => {
    const member = getMember(id);

    if (!member) {
        return false;
    }

    const {addedTimestamp} = member;

    if (!addedTimestamp) {
        return false;
    }

    return addedTimestamp > getTimestamp() - daysToMilliseconds(14);
};

export const getMenCount = () => getMembers().filter(({gender}) => gender === Gender.man).length;

export const getWomenCount = () => getMembers().filter(({gender}) => gender === Gender.woman).length;

export const getMembersCount = () => getMembers().length;
