import React, {FunctionComponent, PropsWithChildren, AnchorHTMLAttributes} from "react";

export type LinkProps = PropsWithChildren<{
    href?: string;
}> & AnchorHTMLAttributes<HTMLAnchorElement>

export const Link: FunctionComponent<LinkProps> = ({
    href = "#",
    children,
    ...rest
}) => (
    <a
        {...{href}}
        {...rest}
        rel="noopener noreferrer"
    >
        {children}
    </a>
);
