import React, {FunctionComponent} from "react";

import {bemClassNames} from "../../../../../../utils";
import {Cell} from "../Cell";
import "./TooltipCell.css";

export type TooltipCellProps = {
    text: string;
    paddingRight?: number;
}

const classNames = bemClassNames("tooltip-cell");

export const TooltipCell: FunctionComponent<TooltipCellProps> = ({children, text, paddingRight}) => (
    <Cell>
        <div className={classNames()} style={{paddingRight}}>
            {children}
            <span className={classNames("text")}>{text}</span>
        </div>
    </Cell>
);
