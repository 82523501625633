import React, {FunctionComponent, PropsWithChildren} from "react";

import "./Row.css";

export type RowProps = PropsWithChildren<{}>

export const Row: FunctionComponent<RowProps> = ({children}) =>(
    <tr className="row">
        {children}
    </tr>
);
