import {Member, Gender, CountryCode} from "../types";

export const members: Member[] = [
    {
        id: "1",
        name: "Adam Raw",
        city: "Ostrava",
        gender: Gender.man,
        levelNumber: "99",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Adam Raw",
            link: "https://www.facebook.com/Adam.Raw.Workout",
        },
        youtube: {
            nick: "Adam Raw",
            link: "https://www.youtube.com/user/Asabado",
        },
        instagramNick: "raw_workout_couple",
        detail: "Raw Workout Founder",
        addedTimestamp: 946674000000,
        website: {
            name: "Adam Raw",
            link: "http://www.adamraw.cz/",
        },
        requirementsVideoLink: "https://youtu.be/K93F6uEfoGk",
    },
    {
        id: "2",
        name: "Monika Najdenovová",
        city: "Havířov",
        gender: Gender.woman,
        levelNumber: "98",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Monika Najdenovová - Amazon princess",
            link: "https://www.facebook.com/Monika-Najdenovová-Amazon-princess-796713190382747",
        },
        youtube: {
            nick: "Monika N - Amazon Princess",
            link: "https://www.youtube.com/channel/UCbtAgRKDHXJ80wR7Cctew1w/videos",
        },
        instagramNick: "amazon.princess.sw",
        addedTimestamp: 946674000000,
    },
    {
        id: "3",
        name: "Denis Křižánek",
        city: "Albrechtice u Č. Těšína",
        gender: Gender.man,
        levelNumber: "2",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Deny Křižánek",
            link: "https://www.facebook.com/deny.krizanek",
        },
        instagramNick: "denykrizanek",
        addedTimestamp: 946674000000,
    },
    {
        id: "4",
        name: "David Pavelka",
        city: "Nivnice",
        gender: Gender.man,
        levelNumber: "1",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "David Pavelka",
            link: "https://www.facebook.com/david.pavelka.900",
        },
        addedTimestamp: 946674000000,
    },
    {
        id: "5",
        name: "Dominika Rigoová",
        city: "Ostrava",
        gender: Gender.woman,
        levelNumber: "1",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Dominika Rigoová",
            link: "https://www.facebook.com/Dominikaworkout",
        },
        instagramNick: "domi_workout22",
        addedTimestamp: 946674000000,
    },
    {
        id: "6",
        name: "Jaroslava Holická",
        city: "Frýdlant",
        gender: Gender.woman,
        levelNumber: "1",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Jaroslava Holická",
            link: "https://www.facebook.com/jaroslava.holicka",
        },
        addedTimestamp: 946674000000,
    },
    {
        id: "7",
        name: "Niki Rich",
        city: "Ostrava",
        gender: Gender.woman,
        levelNumber: "1",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Niki",
            link: "https://www.facebook.com/NikiRaw",
        },
        youtube: {
            nick: "Niki",
            link: "https://www.youtube.com/channel/UC--nw3YjWJGCg2ea6OGAdVQ/videos",
        },
        addedTimestamp: 946674000000,
    },
    {
        id: "8",
        name: "Adam Melichar",
        city: "Nivnice",
        gender: Gender.man,
        levelNumber: "1",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Adam Melichar",
            link: "https://www.facebook.com/adam.melichar.33",
        },
        addedTimestamp: 946674000000,
    },
    {
        id: "9",
        name: "Eva Ďoubalová",
        city: "Chomutice",
        gender: Gender.woman,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Eva Ďoubalová",
            link: "https://www.facebook.com/profile.php?id=100007603532693",
        },
        instagramNick: "evadoubalova",
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/fnqQkhaQpfE",
    },
    {
        id: "10",
        name: "Jan Makula",
        city: "Přeštice",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Jan Makula",
            link: "https://www.facebook.com/jan.makula.1",
        },
        youtube: {
            nick: "Jan Makula",
            link: "https://www.youtube.com/channel/UCjVoG4uh2PmxK44CriX-pmw",
        },
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/I0n0IPQA8u8",
    },
    {
        id: "11",
        name: "Josef Kováč",
        city: "České Budějovice",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Josef Kováč",
            link: "https://www.facebook.com/josef.kovac.5",
        },
        youtube: {
            nick: "BEAST EviL",
            link: "https://www.youtube.com/channel/UC7xuipx0zjoncvjrlVgvF1Q",
        },
        instagramNick: "josef.kovac_beast.evil",
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/0ycNob1wklU",
    },
    {
        id: "12",
        name: "Marie Nová",
        city: "Praha",
        gender: Gender.woman,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Vegan sport club",
            link: "https://www.facebook.com/vegansportclub",
        },
        addedTimestamp: 946674000000,
    },
    {
        id: "13",
        name: "Michal Šopor",
        city: "living in the USA",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Michal Sopor",
            link: "https://www.facebook.com/michalsoporCZ",
        },
        youtube: {
            nick: "Michal Šopor",
            link: "https://www.youtube.com/user/M4jkl2",
        },
        instagramNick: "michalsopor",
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/tPJSSiqAcf0",
    },
    {
        id: "14",
        name: "Nikola Řeháčková",
        city: "living in the UK",
        gender: Gender.woman,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Nikola Řeháčková",
            link: "https://www.facebook.com/nikola.rehackova.7",
        },
        addedTimestamp: 946674000000,
    },
    {
        id: "15",
        name: "Samuel Pasler",
        city: "Říčany u Prahy",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Sam Pasler",
            link: "https://www.facebook.com/sam.pasler",
        },
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/hD6ZADwV7QM",
    },
    {
        id: "16",
        name: "Miro Kúdela",
        city: "Topolčany",
        gender: Gender.man,
        levelNumber: "1",
        countryCode: CountryCode.SK,
        facebook: {
            nick: "Miro Kúdela training",
            link: "https://www.facebook.com/mirokudelatraining",
        },
        youtube: {
            nick: "Miro Kúdela training",
            link: "https://www.youtube.com/user/Mirkotron",
        },
        instagramNick: "miro.kudela",
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/QWMjK7jdnpY",
    },
    {
        id: "17",
        name: "Erik Garaj",
        city: "Želovce",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.SK,
        facebook: {
            nick: "Erik Garaj",
            link: "https://www.facebook.com/Erik-Garaj-1246352872059198",
        },
        youtube: {
            nick: "Erik Garaj",
            link: "https://www.youtube.com/channel/UCC6HpsodV3NE5ikBl5SokNg",
        },
        instagramNick: "erik_garaj_1",
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/J1pjbDM04Pw",
    },
    {
        id: "18",
        name: "Jozef Staroba",
        city: "Zlaté Moravce",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.SK,
        facebook: {
            nick: "Jozef Staroba",
            link: "https://www.facebook.com/jozef.staroba",
        },
        detail: "On the left side",
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/uNJOTw1LJ-k",
    },
    {
        id: "19",
        name: "Juraj Drienovský",
        city: "Zlaté Moravce",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.SK,
        facebook: {
            nick: "Juraj Drienovský",
            link: "https://www.facebook.com/juraj.drienovsky.3",
        },
        youtube: {
            nick: "Ernie Drienovsky",
            link: "https://www.youtube.com/channel/UCm_GSA4TknuwVVoZ-xAPpJA/videos",
        },
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/uNJOTw1LJ-k",
    },
    {
        id: "20",
        name: "Lukáš Holoňa",
        city: "living in the USA",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.SK,
        facebook: {
            nick: "Lukas Holoña Mach",
            link: "https://www.facebook.com/lukas.h.mach",
        },
        youtube: {
            nick: "calisthenicstillidie",
            link: "https://www.youtube.com/user/lukasholo/videos",
        },
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/y-Nr5A5rCW4",
    },
    {
        id: "21",
        name: "Marcel Sirota",
        city: "Tisovec",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.SK,
        facebook: {
            nick: "Marcelko Sirota",
            link: "https://www.facebook.com/MarcelkoRytmusko",
        },
        addedTimestamp: 946674000000,
    },
    {
        id: "22",
        name: "Marianna Dvorščáková",
        city: "Prešov",
        gender: Gender.woman,
        levelNumber: "0",
        countryCode: CountryCode.SK,
        facebook: {
            nick: "Marianna Dvorščáková",
            link: "https://www.facebook.com/marianna.dvorscakova",
        },
        instagramNick: "mariannusik",
        addedTimestamp: 946674000000,
    },
    {
        id: "23",
        name: "Samuel Švábik",
        city: "Považská Bystrica",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.SK,
        facebook: {
            nick: "Samuel Švábik",
            link: "https://www.facebook.com/profile.php?id=100012153374591",
        },
        addedTimestamp: 946674000000,
    },
    {
        id: "24",
        name: "Tania Raw",
        city: "Zhitomir",
        gender: Gender.woman,
        levelNumber: "2",
        countryCode: CountryCode.UKR,
        facebook: {
            nick: "Tania Raw",
            link: "https://www.facebook.com/tatiana.koretskaya.5",
        },
        instagramNick: "raw_workout_couple",
        detail: "Ukrainian champion of street workout girls freestyle and sets and reps of 2016",
        addedTimestamp: 946674000000,
    },
    {
        id: "25",
        name: "Bine Šuljić",
        city: "Šempas",
        gender: Gender.man,
        levelNumber: "1",
        countryCode: CountryCode.SLO,
        facebook: {
            nick: "Bine Suljic - Calisthenics",
            link: "https://www.facebook.com/binesuljic/",
        },
        youtube: {
            nick: "Bine Suljic",
            link: "https://www.youtube.com/binesuljic",
        },
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/y13iF63KLvY",
    },
    {
        id: "26",
        name: "Lorena Klasnić",
        city: "Črnomelj",
        gender: Gender.woman,
        levelNumber: "1",
        countryCode: CountryCode.SLO,
        facebook: {
            nick: "Lorena Klasnić",
            link: "https://www.facebook.com/lorena.klasnic",
        },
        detail: "Dominik SKY Team",
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/2pnwrZl5vto",
    },
    {
        id: "27",
        name: "Billal Tobbal Seghir",
        city: "Medea",
        gender: Gender.man,
        levelNumber: "2",
        countryCode: CountryCode.ALG,
        facebook: {
            nick: "Billal Tobbal",
            link: "https://www.facebook.com/billlllllly",
        },
        youtube: {
            nick: "billal tobbal",
            link: "https://www.youtube.com/user/bboybillal/videos",
        },
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/N8EsONduVVE",
    },
    {
        id: "29",
        name: "Stefano Falcioni",
        city: "Fabriano",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.ITA,
        facebook: {
            nick: "Stefano Falcioni",
            link: "https://www.facebook.com/stefano.falcioni",
        },
        youtube: {
            nick: "Stefano Falcioni",
            link: "https://www.youtube.com/user/Neikos866/videos",
        },
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/l1qreZb9GCg",
    },
    {
        id: "30",
        name: "Josh Zuza",
        city: "Manassas",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.USA,
        facebook: {
            nick: "Josh Zuza",
            link: "https://www.facebook.com/josh.zuza",
        },
        addedTimestamp: 946674000000,
    },
    {
        id: "31",
        name: "Andreas Aravena",
        city: "Rosario",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.ARG,
        facebook: {
            nick: "Andreas Aravena",
            link: "https://www.facebook.com/andres.aravena",
        },
        youtube: {
            nick: "Andreas Aravena",
            link: "https://www.youtube.com/channel/UCERKMxQKI9twxm0pUrx7Dvw/videos",
        },
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/i0l7AUuumC0",
    },
    {
        id: "32",
        name: "Damian Sobico",
        city: "Buenos Aires",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.ARG,
        facebook: {
            nick: "Damian Sobico",
            link: "https://www.facebook.com/KetoBuffed/",
        },
        youtube: {
            nick: "KetoBuffed",
            link: "https://www.youtube.com/ketobuffed",
        },
        instagramNick: "ketobuffed",
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/YXgM60qPBGQ",
    },
    {
        id: "33",
        name: "Sofia Cantone",
        city: "Buenos Aires",
        gender: Gender.woman,
        levelNumber: "0",
        countryCode: CountryCode.ARG,
        facebook: {
            nick: "Sofía Cantone Nuñez",
            link: "https://www.facebook.com/sofiacantone",
        },
        youtube: {
            nick: "Sofia Strong",
            link: "https://www.youtube.com/user/taupau/videos",
        },
        instagramNick: "soficn",
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/qFCmMVXAcAg",
    },
    {
        id: "34",
        name: "Iker Libano Bollar",
        city: "Markina Xemein - Bizkaia",
        gender: Gender.man,
        levelNumber: "1",
        countryCode: CountryCode.SPA,
        facebook: {
            nick: "",
            link: "",
        },
        youtube: {
            nick: "Invizi",
            link: "https://www.youtube.com/channel/UCh-LgSfn4XEWFTDWDjk_tPQ",
        },
        instagramNick: "iker_libano",
        addedTimestamp: 946674000000,
    },
    {
        id: "35",
        name: "Paweł Janosik",
        city: "Biskupice/Pilica",
        gender: Gender.man,
        levelNumber: "1",
        countryCode: CountryCode.POL,
        facebook: {
            nick: "Paweł Janosik",
            link: "https://www.facebook.com/profile.php?id=100000373229070",
        },
        youtube: {
            nick: "Paweł Janosik",
            link: "https://www.youtube.com/channel/UCJfjLnLmnBcEla9I8JTrzCA/videos",
        },
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/1So0PAXP2F0",
    },
    {
        id: "36",
        name: "Kritarth Chauhan",
        city: "New Delhi",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.IND,
        facebook: {
            nick: "Kritarth Chauhan",
            link: "https://www.facebook.com/kretard.kun",
        },
        youtube: {
            nick: "Chauhan Kritarth",
            link: "https://www.youtube.com/user/Kritarth12/videos",
        },
        instagramNick: "mylifeonbars",
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/QuYXhV9t_Ng",
    },
    {
        id: "37",
        name: "Ally Kane",
        city: "Belfast",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.IRE,
        facebook: {
            nick: "Ally Kane",
            link: "https://www.facebook.com/ally.kane.7",
        },
        youtube: {
            nick: "allykane32",
            link: "https://www.youtube.com/user/allykane32/videos",
        },
        addedTimestamp: 946674000000,
        requirementsVideoLink: "https://youtu.be/_TpavY54TEc",
    },
    {
        id: "38",
        name: "Ágnes Ipkovics",
        city: "Szombathely",
        gender: Gender.woman,
        levelNumber: "0",
        countryCode: CountryCode.HUN,
        facebook: {
            nick: "Ágnes Ipkovics",
            link: "https://www.facebook.com/agnes.ipkovics",
        },
        addedTimestamp: 946674000000,
    },
    {
        id: "39",
        name: "Patrik Picek",
        city: "Prostějov",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Paťa Picků",
            link: "https://www.facebook.com/pata.picku",
        },
        instagramNick: "pataspic",
        addedTimestamp: 1490005974000,
        requirementsVideoLink: "https://youtu.be/qGY9cT6V2gE",
    },
    {
        id: "40",
        name: "Pavel Pilch",
        city: "Nýdek",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        addedTimestamp: 1492517010000,
        requirementsVideoLink: "https://youtu.be/LDbh1MqEGf4",
    },
    {
        id: "41",
        name: "Romana Dianišková",
        city: "Tisovec",
        gender: Gender.woman,
        levelNumber: "0",
        countryCode: CountryCode.SK,
        facebook: {
            nick: "Romana Dianišková",
            link: "https://www.facebook.com/romanka.dianiskova",
        },
        addedTimestamp: 1492641758000,
    },
    {
        id: "42",
        name: "Petr Šebesta",
        city: "Ivanovice na Hané",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "",
            link: "",
        },
        instagramNick: "arkham_knigh7",
        detail: "Straight natural way, Be water!",
        addedTimestamp: 1492678760000,
        requirementsVideoLink: "https://youtu.be/XNQIBavZWCk",
    },
    {
        id: "43",
        name: "Marek Jano",
        city: "living in Belgium",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.SK,
        facebook: {
            nick: "Marek Jano",
            link: "https://www.facebook.com/MarekJano27",
        },
        instagramNick: "marek_jano",
        addedTimestamp: 1492943619000,
    },
    {
        id: "46",
        name: "Valentin Novikov",
        city: "Kiev",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.UKR,
        facebook: {
            nick: "Valentin Valeo Novikov",
            link: "https://www.facebook.com/valentin.valeo",
        },
        instagramNick: "valeonovikov",
        addedTimestamp: 1497528422000,
    },
    {
        id: "47",
        name: "Julia Snigirova",
        city: "Kiev",
        gender: Gender.woman,
        levelNumber: "0",
        countryCode: CountryCode.UKR,
        facebook: {
            nick: "Julia Snigirova",
            link: "https://www.facebook.com/people/%D0%AE%D0%BB%D0%B8%D1%8F-%D0%A1%D0%BD%D0%B8%D0%B3%D0%B8%D1%80%D0%B5%D0%B2%D0%B0/100012938103414",
        },
        instagramNick: "yliasnigireva",
        addedTimestamp: 1497528422000,
    },
    {
        id: "48",
        name: "Evgeny Gabor",
        city: "Saratov",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.RUS,
        facebook: {
            nick: "",
            link: "",
        },
        youtube: {
            nick: "Ewgenii GaboR",
            link: "https://www.youtube.com/channel/UC3RYTUjCVEfXhe8R2H5Z4fg",
        },
        instagramNick: "ewgenii_official",
        addedTimestamp: 1501757837000,
    },
    {
        id: "49",
        name: "Andraž Kobal",
        city: "Štanjel",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.SLO,
        facebook: {
            nick: "Andraz Kobal",
            link: "https://www.facebook.com/andraz.kobal",
        },
        youtube: {
            nick: "Andraz Kobal",
            link: "https://www.youtube.com/channel/UCueeiFR3KaMj0v9EiOTBX-w",
        },
        instagramNick: "andraz_kobal",
        addedTimestamp: 1501758261000,
    },
    {
        id: "50",
        name: "Natálie Mučicová",
        city: "Ostrava",
        gender: Gender.woman,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Natálie Mučicová",
            link: "https://www.facebook.com/natalie.mucicova.1",
        },
        instagramNick: "nataliemucicova",
        addedTimestamp: 1506243617000,
    },
    {
        id: "51",
        name: "Lukáš Nociar",
        city: "Ostrava",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "",
            link: "",
        },
        addedTimestamp: 1508081605000,
    },
    {
        id: "52",
        name: "Dima Abdul",
        city: "Kiev",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.UKR,
        facebook: {
            nick: "Дмитрий Абдул",
            link: "https://www.facebook.com/profile.php?id=100013296000491",
        },
        addedTimestamp: 1508447963000,
    },
    {
        id: "53",
        name: "Ajem Fit",
        city: "Ostrava",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Ajem Fit",
            link: "https://www.facebook.com/AjemFit/",
        },
        instagramNick: "ajem_fit",
        addedTimestamp: 1508832806000,
    },
    {
        id: "54",
        name: "Dan Kyslík",
        city: "Smiřice",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Dan Kyslík",
            link: "https://www.facebook.com/dan.kyslik",
        },
        youtube: {
            nick: "daky O2",
            link: "https://www.youtube.com/channel/UCzcK2Duv4yV7J9J5ysycPvg",
        },
        instagramNick: "dankyslik",
        addedTimestamp: 1509395164000,
        requirementsVideoLink: "https://youtu.be/ZFp2sIMWvRE",
    },
    {
        id: "55",
        name: "Vadim Zablotskyi",
        city: "Žitomir",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.UKR,
        facebook: {
            nick: "Вадим Заблоцкий",
            link: "https://www.facebook.com/zablotskyivadym",
        },
        youtube: {
            nick: "Вадим Заблоцкий",
            link: "https://www.youtube.com/channel/UCwb6idshiICPiFWmhxfofmg/videos",
        },
        addedTimestamp: 1513546270000,
        requirementsVideoLink: "https://youtu.be/rO0i_-DiLe0",
    },
    {
        id: "56",
        name: "Jindřich Mazanec",
        city: "Chodov",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "hensynsløs",
            link: "https://www.facebook.com/jindramallej.mazec",
        },
        youtube: {
            nick: "Jindra Mazanec - RAW Workout",
            link: "https://www.youtube.com/channel/UC-ofZjMGhMlTMGcp6UQFSXg",
        },
        instagramNick: "jindra_mazanec",
        addedTimestamp: 1517933111000,
        requirementsVideoLink: "https://youtu.be/HPzoyuOnD9M",
    },
    {
        id: "63",
        name: "Jakub Výmola",
        city: "Nivnice",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Jakub Výmola",
            link: "https://www.facebook.com/jakub.vymola.56",
        },
        addedTimestamp: 1519303110000,
    },
    {
        id: "64",
        name: "Kevin Gořalík",
        city: "Holešov",
        gender: Gender.man,
        levelNumber: "0",
        countryCode: CountryCode.CZ,
        facebook: {
            nick: "Kevin Lorenc",
            link: "https://www.facebook.com/kevin.lorenc.5",
        },
        instagramNick: "kevinlorin",
        addedTimestamp: 1526457750000,
    },
    {
        id: "65",
        name: "Ahmed Nadjib Benzaid",
        city: "Medea",
        gender: Gender.man,
        levelNumber: "1",
        countryCode: CountryCode.ALG,
        facebook: {
            nick: "Nadjib Benzaid",
            link: "https://www.facebook.com/benzaidnadjib",
        },
        youtube: {
            nick: "Nadjib Benzaid",
            link: "https://www.youtube.com/channel/UCKqOKz_OLHb9BHxE_FWa1yw",
        },
        instagramNick: "nadjib_benzaid",
        addedTimestamp: 1560014730000,
        requirementsVideoLink: "https://youtu.be/To8H5dfrfbc",
    },
    {
        id: "66",
        name: "Tereza Votrubová",
        countryCode: CountryCode.CZ,
        gender: Gender.woman,
        levelNumber: "0",
        city: "Praha",
        addedTimestamp: 1678403657000,
    },
    {
        id: "67",
        name: "Júlia Matejčíková",
        countryCode: CountryCode.CZ,
        gender: Gender.woman,
        levelNumber: "0",
        city: "Praha",
        addedTimestamp: 1679141433000,
        requirementsVideoLink: "https://youtu.be/_Si3dwbGl-A",
        instagramNick: "juliamatejcikova",
    },
    {
        id: "68",
        name: "Jeremy Whiteside",
        countryCode: CountryCode.AU,
        gender: Gender.man,
        levelNumber: "0",
        city: "Sydney",
        addedTimestamp: 1678403657000,
        requirementsVideoLink: "https://youtu.be/7ZmIqTRlYyk",
        instagramNick: "je_remyw",
    },
    {
        id: "69",
        name: "Lukáš Reisinger",
        countryCode: CountryCode.CZ,
        gender: Gender.man,
        levelNumber: "0",
        addedTimestamp: 1682856435000,
        city: "Praha",
        instagramNick: "kouc_reisinger",
        website: {
            name: "Lukáš Reisinger",
            link: "http://lreisinger.cz/",
        },
        requirementsVideoLink: "https://youtu.be/YpuUF8RVuz8",
    },
];
