import {setAlpha, count} from "./utils";
import {Graph, Gender} from "./types";
import {levels} from "./data/levels";
import {countries} from "./data/countries";
import {getMembers} from "./selectors";

const getGenderGraph = (gender: Gender) => {
    const groupMembers = getMembers().filter((members) => members.gender === gender);
    const usedLevelNumbers = groupMembers.sort((a, b) => parseInt(a.levelNumber) - parseInt(b.levelNumber)).map(({levelNumber}) => levelNumber);
    const uniqueUsedLevelNumbers = Array.from(new Set(usedLevelNumbers));
    const labels = uniqueUsedLevelNumbers.map((levelNumber) => {
        const level = levels[levelNumber]
        return gender === Gender.man ? level.man?.title : level.woman?.title;
    }) as string[];
    const colors = uniqueUsedLevelNumbers.map((levelNumber) => levels[levelNumber].color) as string[];
    const data = uniqueUsedLevelNumbers.map((levelNumber) => usedLevelNumbers.filter((level) => level === levelNumber).length);

    return {
        name: gender === Gender.woman ? "Women" : "Men",
        labels,
        datasets: [{
            data,
            backgroundColor: colors.map((color) => setAlpha(color, 0.8)),
            hoverBackgroundColor: colors,
        }],
    };
}

const getManGraph = (): Graph => getGenderGraph(Gender.man);

const getWomanGraph = (): Graph => getGenderGraph(Gender.woman);

const getCountryGraph = (): Graph => {
    const usedCountryCodes = getMembers().map(({countryCode}) => countryCode);
    const sortedUsedCountryCodes = usedCountryCodes.sort((a, b) => a.localeCompare(b)).sort((a, b) => count(usedCountryCodes, b) - count(usedCountryCodes, a));
    const uniqueUsedCountryCodes = Array.from(new Set(sortedUsedCountryCodes));
    const labels = uniqueUsedCountryCodes.map((countryCode) => countries[countryCode].name);
    const colors = uniqueUsedCountryCodes.map((countryCode) => countries[countryCode].color);
    const data = uniqueUsedCountryCodes.map((countryCode) => count(sortedUsedCountryCodes, countryCode));

    return {
        name: "Country",
        labels,
        datasets: [{
            data,
            backgroundColor: colors.map((color) =>setAlpha(color, 0.8)),
            hoverBackgroundColor: colors,
        }],
    };
};

const getGendersGraph = () => {
    const members = getMembers();
    const menCount = members.filter(({gender}) => gender === Gender.man).length;
    const womenCount = members.filter(({gender}) => gender === Gender.woman).length;
    const menColor = "#1368bf";
    const womenColor = "#bf2313";

    return {
        name: "Gender",
        labels: ["Men", "Women"],
        datasets: [{
            data: [menCount, womenCount],
            backgroundColor: [setAlpha(menColor, 0.8), setAlpha(womenColor, 0.8)],
            hoverBackgroundColor: [menColor, womenColor],
        }],
    };
};

export const graphs: Graph[] = [
    getManGraph(),
    getWomanGraph(),
    getCountryGraph(),
    getGendersGraph(),
];
